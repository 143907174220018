import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };


  const toggleDropdown = (menuItem) => {
    if (window.innerWidth > 768) {  // Only toggle for desktop
      setActiveDropdown(activeDropdown === menuItem ? null : menuItem);
    }
  };

  const handleLabelClick = (link) => {
    setMobileMenuOpen(false);
    navigate(link);
  };

  const handleChildClick = (parentLink, childId) => {
    setMobileMenuOpen(false);
    setActiveDropdown(null);  // Close dropdown after click
    navigate(parentLink, { state: { scrollTo: childId } });
  };
  

  const menuItems = [
    {
      label: 'Home',
      link: '/',
      children: [
        { label: 'Introduction to SANFIR', id: 'introduction' },
        { label: 'Mission and Vision statements', id: 'mission-vision' },
        { label: 'Call to Action', id: 'call-to-action' },
      ],
    },
    {
      label: 'About Us',
      link: '/about',
      children: [
        { label: 'History of SANFIR', id: 'history' },
        { label: 'Our Board', id: 'board' },
        { label: 'Meet the Team', id: 'team' },
      ],
    },
    {
      label: 'Our Work',
      link: '/our-work',
      children: [
        { label: 'Programs and Services', id: 'programs' },
        { label: 'Research and Publications', id: 'research' },
        { label: 'Advocacy and Policy', id: 'advocacy' },
      ],
    },
    {
      label: 'Membership',
      link: '/membership',
      children: [
        { label: 'Membership Application Form', id: 'application' },
        { label: 'Member Directory', id: 'directory' },
      ],
    },
    {
      label: 'Get Involved',
      link: '/get-involved',
      children: [
        { label: 'Volunteer Opportunities', id: 'volunteer' },
        { label: 'Donate', id: 'donate' },
        { label: 'Fundraising Campaigns', id: 'fundraising' },
        { label: 'Events Calendar', id: 'events' },
      ],
    },
    {
      label: 'Resources',
      link: '/resources',
      children: [
        { label: 'Library', id: 'library' },
        { label: 'FAQs', id: 'faqs' },
        { label: 'Glossary', id: 'glossary' },
        { label: 'Links', id: 'links' },
      ],
    },
    {
      label: 'News and Blogs',
      link: '/news',
      children: [
        { label: 'Latest News', id: 'latest-news' },
        { label: 'Blog', id: 'blog' },
        { label: 'Newsletter Archive', id: 'newsletter' },
      ],
    },
    {
      label: 'Contact',
      link: '/contact',
      children: [
        { label: 'Contact Form', id: 'contact-form' },
        { label: 'Email', id: 'email' },
        { label: 'Phone Number', id: 'phone' },
        { label: 'Social Media Links', id: 'social-media' },
      ],
    },
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarBrand}>
        <h1>SANIR</h1>
        <button 
          className={styles.mobileMenuButton} 
          onClick={toggleMobileMenu}
          aria-label="Toggle navigation menu"
        >
          ☰
        </button>
      </div>

      <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
        {menuItems.map((item, index) => (
          <li 
            key={index} 
            className={styles.navItem}
            onMouseEnter={() => toggleDropdown(item.label)}
            onMouseLeave={() => toggleDropdown(null)}
          >
            <div className={styles.dropdown}>
              <button
                className={styles.navLink}
                onClick={(e) => {
                  e.stopPropagation();
                  handleLabelClick(item.link);
                }}
              >
                {item.label}
              </button>
              <ul 
                className={`${styles.dropdownMenu} ${activeDropdown === item.label ? styles.dropdownActive : ''}`}
              >
                {item.children.map((child, childIndex) => (
                  <li key={childIndex} className={styles.dropdownItem}>
                    <button
                      className={styles.dropdownLink}
                      onClick={() => handleChildClick(item.link, child.id)}
                    >
                      {child.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;