import React from 'react';
import styles from './Footer.module.css';
import 'font-awesome/css/font-awesome.min.css'; // import Font Awesome styles

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <p>© {currentYear} Sanfir. All rights reserved.</p>
      <p>Follow us on social media for updates:</p>
      <div className={styles.socialIcons}>
        <a href="https://www.facebook.com/profile.php?id=61570196095232&rdid=WHRLYtnOmYowwt8D&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F163sd41ZsZ%2F#" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </a>
        <a href="https://x.com/AfricaFor72061?t=285lUGwDT4XxjM_Tx0K_Gg&s=09" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
