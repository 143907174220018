import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import OurWork from './pages/OurWork';
import Membership from './pages/Membership';
import GetInvolved from './pages/GetInvolved';
import Resources from './pages/Resources';
import News from './pages/News';
import Contact from './pages/Contact';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Header />
        <main className="main-content">
          <Routes>
            {/* Home Routes */}
            <Route path="/" element={<Home />} />
            
            {/* About Routes */}
            <Route path="/about" element={<About />} />
            <Route path="/about/*" element={<About />} />
            
            {/* Our Work Routes */}
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/our-work/*" element={<OurWork />} />
            
            {/* Membership Routes */}
            <Route path="/membership" element={<Membership />} />
            <Route path="/membership/*" element={<Membership />} />
            
            {/* Get Involved Routes */}
            <Route path="/get-involved" element={<GetInvolved />} />
            <Route path="/get-involved/*" element={<GetInvolved />} />
            
            {/* Resources Routes */}
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/*" element={<Resources />} />
            
            {/* News Routes */}
            <Route path="/news" element={<News />} />
            <Route path="/news/*" element={<News />} />
            
            {/* Contact Routes */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/*" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;