import React from 'react';
import styles from './Resources.module.css';
import document from '../assets/SANFIR.pdf';

const Resources = () => {
  return (
    <section className={styles.resourcesSection}>
      <h2 className={styles.resourcesTitle}>Resources</h2>
      <p className={styles.resourcesDescription}>
        Access educational materials and research reports on human rights topics.
      </p>

      <div className={styles.librarySection}>
        <h3 className={styles.libraryTitle}>Library</h3>
        <ul className={styles.documentsList}>
          <li className={styles.documentItem}>
            <a
              href={document}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.documentLink}
            >
              Sanfir Publication - Migrant Rejected Service
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Resources;
