import React from 'react';
import styles from './OurWork.module.css';

const OurWork = () => {
  return (
    <section className={styles.ourWorkSection}>
      <h2 className={styles.sectionTitle}>Our Work</h2>
      <p className={styles.sectionDescription}>
        We are committed to making a positive impact through our various initiatives and projects. Explore our work and see how we are contributing to the community.
      </p>

      <div className={styles.projectsSection}>
        <h3 className={styles.projectsTitle}>Our Projects</h3>
        <div className={styles.projectsList}>
          <div className={styles.projectItem}>
            <h4 className={styles.projectTitle}>Project 1</h4>
            <p className={styles.projectDescription}>
              A brief description of the first project. This project focuses on making a difference in the community through sustainable practices.
            </p>
          </div>
          <div className={styles.projectItem}>
            <h4 className={styles.projectTitle}>Project 2</h4>
            <p className={styles.projectDescription}>
              A brief description of the second project. This initiative aims to provide educational resources to underserved communities.
            </p>
          </div>
          <div className={styles.projectItem}>
            <h4 className={styles.projectTitle}>Project 3</h4>
            <p className={styles.projectDescription}>
              A brief description of the third project. This project focuses on health and wellness initiatives for local populations.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.callToActionSection}>
        <h3 className={styles.callToActionTitle}>Get Involved</h3>
        <p className={styles.callToActionDescription}>
          Join us in making a difference. Whether through volunteering, donating, or spreading the word, your support helps us continue our work.
        </p>
        <button className={styles.callToActionButton}>Learn More</button>
      </div>
    </section>
  );
};

export default OurWork;
