import React from 'react';
import styles from './News.module.css';

const News = () => {
  return (
    <section className={styles.newsSection}>
      <h2 className={styles.sectionTitle}>Latest News</h2>
      <p className={styles.sectionDescription}>
        Stay up to date with the latest updates, events, and news from our organization.
      </p>

      <div className={styles.newsList}>
        <div className={styles.newsItem}>
          <h3 className={styles.newsTitle}>New Partnership Announcement</h3>
          <p className={styles.newsExcerpt}>
            We are excited to announce a new partnership with XYZ Organization to support our community programs. Read more about how this collaboration will impact our initiatives.
          </p>
          <button className={styles.readMoreButton}>Read More</button>
        </div>

        <div className={styles.newsItem}>
          <h3 className={styles.newsTitle}>Annual Fundraiser Event</h3>
          <p className={styles.newsExcerpt}>
            Our annual fundraiser event was a great success! Thanks to your support, we raised funds to continue our mission of providing education and resources to underserved communities.
          </p>
          <button className={styles.readMoreButton}>Read More</button>
        </div>

        <div className={styles.newsItem}>
          <h3 className={styles.newsTitle}>Human Rights Awareness Campaign</h3>
          <p className={styles.newsExcerpt}>
            Our latest campaign focuses on raising awareness about human rights issues affecting marginalized communities. Join us in advocating for change and justice.
          </p>
          <button className={styles.readMoreButton}>Read More</button>
        </div>
      </div>
    </section>
  );
};

export default News;
