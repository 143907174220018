import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './About.module.css';

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <section className={styles.aboutSection}>
      <h2 className={styles.aboutTitle}>About Us</h2>
      <p className={styles.aboutDescription}>
        Sanir works globally to promote human rights and protect individual freedoms.
      </p>

      {/* History Section */}
      <div id="history" className={styles.historySection}>
        <h3 className={styles.historyTitle}>History of SANIR</h3>
        <p className={styles.historyDescription}>
          SANIR is a vibrant network of communities and organisations led by refugees and migrants in the Southern Africa region. Our network consists of Refugee-Led Organisations (RLOs), Community-Based Organisations (CBOs), and Faith-Based Organisations (FBOs), who are all collectively working to uplift and support refugees and migrants in the region.
        </p>
      </div>

      {/* Our Board Section */}
      <div id="board" className={styles.boardSection}>
        <h3 className={styles.boardTitle}>Our Board</h3>

        {/* Executive Committee */}
        <div className={styles.boardSubsection}>
          <h4 className={styles.subsectionTitle}>Executive Committee</h4>
          <p className={styles.boardDescription}>
            Our Executive Committee plays a crucial role in the day-to-day management and operational decisions of the network. It is responsible for implementing the policies set by the board of directors and ensuring effective program delivery and managing relationships with key stakeholders.
          </p>
          <ul className={styles.boardMembersList}>
            <li><strong>Secretariat</strong> – Sabina Taderera</li>
            <li><strong>Head of Partnership and Programs</strong> – Claude Marie Bernard Kayitare</li>
            <li><strong>Finance Officer</strong> – [Position Unspecified]</li>
          </ul>
        </div>

        {/* Advisory Body */}
        <div className={styles.boardSubsection}>
          <h4 className={styles.subsectionTitle}>Advisory Body</h4>
          <p className={styles.boardDescription}>
            The Advisory Council is a body of respected experts, community leaders, and former refugees or migrants who provide strategic advice and insights to the network. They support the Board and Executive Committee in matters related to program implementation, policy advocacy, and external relations. Members of the Advisory Council are appointed for their deep understanding of refugee and migrant issues, their connections within the wider civil society and governmental sectors, and their ability to provide high-level counsel to network leadership.
          </p>
          <ul className={styles.boardMembersList}>
            <li><strong>Network Advisory Chairperson</strong> – Ronney Kapaso</li>
            <li><strong>Research and Communications</strong> – Derric Yuh Ndim</li>
            <li><strong>Programs Coordinators</strong> – Prince Mungate</li>
          </ul>
        </div>

        {/* Regional and National Committees */}
        <div className={styles.boardSubsection}>
          <h4 className={styles.subsectionTitle}>Regional and National Committees</h4>
          <p className={styles.boardDescription}>
            SANIR operates across multiple countries in the Southern Africa region, and as such, it has Regional and National Committees that help manage local activities and coordinate efforts at the country level. These committees work closely with the executive committee to implement regional and national programs and ensure alignment with SANIR's overall goals and objectives. These committees consist of representatives from SANIR member organizations within each region or country and are instrumental in adapting SANIR’s strategies to local contexts.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
