import React from 'react';
import styles from './GetInvolved.module.css';

const GetInvolved = () => {
  return (
    <section className={styles.getInvolvedSection}>
      <h2 className={styles.sectionTitle}>Get Involved</h2>
      <p className={styles.sectionDescription}>
        Join us in making a difference! There are several ways you can contribute to our mission and help create positive change in the community.
      </p>

      <div className={styles.involvementOptions}>
        <div className={styles.option}>
          <h3 className={styles.optionTitle}>Volunteer</h3>
          <p className={styles.optionDescription}>
            Volunteer your time and skills to support our projects and events. Your contribution will make a direct impact on the communities we serve.
          </p>
          <button className={styles.optionButton}>Learn More</button>
        </div>

        <div className={styles.option}>
          <h3 className={styles.optionTitle}>Donate</h3>
          <p className={styles.optionDescription}>
            Make a difference by donating to our cause. Your generous contributions help fund our programs and outreach efforts.
          </p>
          <button className={styles.optionButton}>Donate Now</button>
        </div>

        <div className={styles.option}>
          <h3 className={styles.optionTitle}>Partner with Us</h3>
          <p className={styles.optionDescription}>
            Partner with us to create lasting change. Whether you're a business or organization, your collaboration can help amplify our efforts.
          </p>
          <button className={styles.optionButton}>Get in Touch</button>
        </div>
      </div>
    </section>
  );
};

export default GetInvolved;
