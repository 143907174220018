import React, { useState } from 'react';
import styles from './Membership.module.css';

const Membership = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    organization: '',
    contactNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted with data:', formData);
    // You can add form submission logic here (e.g., save to Firestore or send to an API)
  };

  return (
    <section className={styles.membershipSection}>
      <h2 className={styles.sectionTitle}>Register for Membership</h2>
      <p className={styles.sectionDescription}>
        Become a member of our community and gain access to exclusive benefits, resources, and events. Fill out the form below to register.
      </p>

      <form className={styles.membershipForm} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="name" className={styles.formLabel}>Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={styles.formInput}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.formLabel}>Email Address</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={styles.formInput}
            required
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="organization" className={styles.formLabel}>Organization Name </label>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="contactNumber" className={styles.formLabel}>Contact Number</label>
          <input
            type="text"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            className={styles.formInput}
            required
          />
        </div>

        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>
    </section>
  );
};

export default Membership;
